/* ChatPopup.css */
body {
    font-family: Arial, sans-serif;
  }
  
  /* Dark theme styles */
  body {
    background-color: #1a1a1a;
    color: #fff;
  }
  
  .chat-popup {
    background-color: #333;
  }
  
  .header {
    background-color: #0084ff;
  }
  
  .message {
    margin-bottom: 10px;
  }
  
  .message .bubble {
    background-color: #fff;
    color: #333;
  }
  
  .user-message {
    background-color: #0084ff;
  }
  
  .receiver-message {
    background-color: #f1f1f1;
  }
  
  .message-time {
    color: #888;
  }
  
  .input-container {
    border-top: 1px solid #666;
    background-color: #1a1a1a;
  }
  
  .input-container input {
    background-color: #333;
    color: #fff;
  }
  
  .send-btn {
    background-color: #0084ff;
    color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  